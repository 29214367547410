import { mapState } from 'vuex';
import { COOKIE_CURRENCY_KEY } from '@motionelements/core/src/app.constants.js';
import { setCookie } from '@motionelements/core/src/services/language.service.js';

export default {
  computed: {
    ...mapState({
      currencyCode: state => state.user.currencyCode,
      currencies: state => state.user.currencies,
      isLoggedIn: state => state.user.isLoggedIn,
    }),
  },
  methods: {
    onChangeLanguage(languageCode) {
      this.$store.dispatch('user/changeLanguage', languageCode)
        .then((path) => {
          if (this.isLoggedIn) {
            if (path) window.location.replace(path);
          } else {
            this.$store.commit('user/setCurrencies', []);
            this.$store.commit('user/setCurrencyCode', '');
            this.$store.dispatch('user/getCurrencies')
              .then(() => {
                if (path) {
                  const defaultCurrency = this.currencies.find((item) => (item.default));
                  if (_.get(defaultCurrency, 'code') && !this.currencyCode) {
                    setCookie(COOKIE_CURRENCY_KEY, defaultCurrency.code);
                  }
                  window.location.replace(path);
                }
              });
          }
        });
    },
  },
};
