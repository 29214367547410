import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';

export default {
  components: {
    NuxtClientOnly: () => import('@motionelements/core/src/components/nav/NuxtClientOnly.vue'),
  },
  mixins: [featureFlagMixin],
  computed: {
    showStudioAiLink() {
      return this.isFeatureEnabled('studio_ai');
    },
    studioAiLink() {
      // @pavlo check if this is still needed after refactor in link.js
      const lang = ['en', 'ja', 'ko', 'zh-hant'].includes(this.$i18n.locale) ? this.$i18n.locale : 'en';
      return process.env.VUE_APP_ENV === 'production'
        ? this.$link('https://ai.motionelements.com', lang).url()
        : this.$link('https://stage-ai.motionelements.com', lang).url();
    },
  },
};
