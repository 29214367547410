//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'main-currency-menu',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState({
      currencies: state => state.user.currencies,
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
};
