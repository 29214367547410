//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'main-menu-language',
  props: {
    value: {
      type: String,
      default: 'en',
    },
    isFooter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      languages: state => state.app.languages,
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },
};
